import './index.css'

/**
 * UTILS
 */
function throttle(callback, wait, immediate = false) {
  let timeout = null 
  let initialCall = true
  
  return function() {
    const callNow = immediate && initialCall
    const next = () => {
      callback.apply(this, arguments)
      timeout = null
    }
    
    if (callNow) { 
      initialCall = false
      next()
    }

    if (!timeout) {
      timeout = setTimeout(next, wait)
    }
  }
}
/**
 * /UTILS
 */

const $form = document.getElementById('form')
const $publicKey = document.getElementById('public_key')
const $error = document.getElementById('error')
const $result = document.getElementById('result')
const $result_input = document.getElementById('result_input')

const statusEnum = {
  CLEAN: 0,
  SUBMITTING: 1,
  OK: 2,
  KO: 3
}

const render = throttle(function render(state) {
  /* Render Error */
  $error.classList.toggle('hidden', !state.error)
  $error.innerText = state.error

  /* Render OK */
  $result.classList.toggle('hidden', typeof state.result === "undefined")
  $result_input.value = state.result
  
}, 300)

const state = new Proxy({
  status: statusEnum.CLEAN,
  error: undefined,
  result: undefined
}, {
  set: (state, property, value) => {
    state[property] = value
    render(state)
  }
})

async function postData(url = '', data = "") {
  const response = await fetch(url, {
    method: 'POST',
    mode: "same-origin",
    cache: 'no-cache', 
    credentials: "omit",
    headers: {
      'Content-Type': 'text/plain'
    },
    body: `${data}`
  });

  return response.text();
}

$form.addEventListener('submit', async e => {
  e.preventDefault()
  
  state.status = statusEnum.SUBMITTING

  try {
    
    const data = await postData('https://hashtoalgorand.com/api', $publicKey.value)
    
    state.result = data.replace('=', '')
    state.status = statusEnum.OK
    
  } catch (err) {

    state.status = statusEnum.KO
    state.error = err.message
    
  }
})
